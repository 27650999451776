.user-menu {
    position: relative;
    cursor: pointer;
}

.user-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    list-style: none;
    margin: 0;
    padding: 0.5rem 0;
    z-index: 1000;
    width: 25vw;
}

.user-dropdown li {
    padding: 0.5rem 1rem;
}

.user-dropdown li a {
    text-decoration: none;
    color: #333;
}

.user-dropdown li:hover {
    background-color: #f0f0f0;
}


.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.close-btn {
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    background: red;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}
